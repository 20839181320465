export const environment = {
  production: false,
  server: 'https://staging-api.myancare.org/v3',
  firebaseConfig: {
    apiKey: 'AIzaSyA6M6nMeC-loRj4jgpDUOmSJy_qWOy2txY',
    authDomain: 'myancare-b9ec0.firebaseapp.com',
    databaseURL: 'https://myancare-b9ec0.firebaseio.com',
    projectId: 'myancare-b9ec0',
    storageBucket: 'myancare-b9ec0.appspot.com',
    messagingSenderId: '106148527407',
    appId: '1:106148527407:web:97566e353cbb7d0bb6b5da',
    measurementId: 'G-3H8W5NB3SK',
  },
};
